import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProductSelectionStepBreadcrumb from "src/components/member-product-selection-steps/ProductSelectStepBreadcrumb";
import ProductInformation from "src/components/member-product-selection-steps/step3/ProductInformation";
import SelectSalePerson from "src/components/member-product-selection-steps/step3/SelectSalePerson";
import memberRoutes from "src/constants/routes/member.routes";
import {
  useAddSalesPersonInInvoiceMutation,
  useDeleteInvoiceByIdMutation,
} from "src/redux/api/saleAndInvoiceApi";
import { IMemberState } from "src/redux/features/memberSlice";
import { StyledHeaderBackButton } from "src/styles/HeaderBackButton";

export interface SalesPersonFields {
  primary_sales_person: string;
  other_sales_person: string;
}

const ProductCouponInfoStep = () => {
  const navigate = useNavigate();

  // LOCAL STATES...
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [salesPersonFields, setSalesPersonFields] = useState({
    primary_sales_person: "",
    other_sales_person: "",
  });

  // REDUX THINGS...
  const { selectedInvoiceId, invoiceDetail } = useSelector(
    (state: any) => state.memberState as IMemberState,
  );

  const [
    addSalesPersonInInvoice,
    {
      isLoading: isAddSalesPersonLoading,
      isSuccess: isAddSalesPersonSuccess,
      error: addSalesPersonError,
    },
  ] = useAddSalesPersonInInvoiceMutation();
  const [deleteInvoiceById, { isSuccess: isDeleteInvoiceSuccess }] =
    useDeleteInvoiceByIdMutation();

  // HANDLE DEFAULT SELECT SALE PERSON...
  useEffect(() => {
    if (invoiceDetail && invoiceDetail.sales_team.length) {
      invoiceDetail.sales_team.forEach((sp) => {
        if (sp.type === "main") {
          setSalesPersonFields((prev) => {
            return { ...prev, primary_sales_person: sp.sales_person_name };
          });
        } else {
          setSalesPersonFields((prev) => {
            return { ...prev, other_sales_person: sp.sales_person_name };
          });
        }
      });
    }
  }, [invoiceDetail]);

  // HANDLE IS MODAL OPEN OR NOT...
  useEffect(() => {
    if (!selectedInvoiceId) {
      setIsDialogOpen(true);
    }
  }, [selectedInvoiceId]);

  // HANDLE SALE PERSON ERROR...
  useEffect(() => {
    if (addSalesPersonError) {
      console.error(`Error in adding sales person: `, addSalesPersonError);
    }
  }, [isAddSalesPersonLoading]);

  useEffect(() => {
    if (isDeleteInvoiceSuccess) {
      navigate("/");
    }
  }, [isDeleteInvoiceSuccess]);

  // HANDLE SALE PERSON SELECTION CHANGES...
  const handleSalesPersonChange = (
    name: keyof SalesPersonFields,
    value: string,
  ) => {
    setSalesPersonFields((prevFields: any) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleGoBack = (url: string) => {
    setIsDialogOpen(false);
    navigate(url, { replace: true });
  };

  const handleCancelOrder = () => {
    deleteInvoiceById({ invoiceId: selectedInvoiceId });
  };

  // SAVE SALE PERSON IN ERP
  const handleSaveSalesPerson = () => {
    const body = {
      sales_team: [
        {
          sales_person: salesPersonFields.primary_sales_person,
          allocated_percentage: 100.0,
          doctype: "Sales Team",
        },
        {
          sales_person: salesPersonFields.other_sales_person,
          allocated_percentage: 0.0,
          doctype: "Sales Team",
        },
      ],
    };
    addSalesPersonInInvoice({ invoiceId: selectedInvoiceId, body });
  };

  return (
    <>
      <Stack spacing={2.5} sx={{ p: 3.75 }}>
        {/* HEADER SECTION... */}
        <Box
          aria-label="header-section"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledHeaderBackButton
            variant="outlined"
            startIcon={<ChevronLeft />}
            onClick={() => navigate(-1)}
          >
            返回
          </StyledHeaderBackButton>

          <Box>
            <ProductSelectionStepBreadcrumb active={3} />
          </Box>

          <Button
            variant="contained"
            startIcon={
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src="/icons/cross.svg"
                  alt="cross-file"
                  width={20}
                  style={{ objectFit: "contain" }}
                />
              </Box>
            }
            sx={{ px: 3.125, py: 1.25 }}
            onClick={handleCancelOrder}
          >
            取消下單
          </Button>
        </Box>

        {/* SALE PERSON INFORMATION... */}
        <SelectSalePerson
          salesPersonFields={salesPersonFields}
          handleSalesPersonChange={(name, value) =>
            handleSalesPersonChange(name as any, value as any)
          }
          // selectedSalePersons={invoiceDetail ? invoiceDetail.sales_team : []}
        />

        {/* PRODUCT INFORMATION... */}
        <ProductInformation
          handleSaveSalesPerson={handleSaveSalesPerson}
          isAddSalesPersonLoading={isAddSalesPersonLoading}
          isAddSalesPersonSuccess={isAddSalesPersonSuccess}
          addSalesPersonError={addSalesPersonError}
        />
      </Stack>

      <Dialog
        open={isDialogOpen}
        // onClose={handleClose}
        aria-labelledby="invoice-not-created-dialog-title"
      >
        <DialogTitle id="invoice-not-created-dialog-title">
          Invoice Is Not Selected
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="invoice-not-created-dialog-description">
            Without selecting invoice we cannot apply Coupon things. <br />
            So, Please select Invoice First
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handleGoBack("/")}
            variant="outlined"
            sx={{ textTransform: "unset" }}
          >
            Go To Member Selection
          </Button>

          <Button
            variant="outlined"
            sx={{ textTransform: "unset" }}
            onClick={() =>
              handleGoBack(memberRoutes.productSelectionStep.selectSupplier)
            }
            autoFocus
          >
            Go To Supplier Selection
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductCouponInfoStep;
