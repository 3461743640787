import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLoaderContainer from "src/components/loader";
import memberRoutes from "src/constants/routes/member.routes";
import { StyledHeaderBackButton } from "src/styles/HeaderBackButton";

import { useDispatch, useSelector } from "react-redux";
import { INVOICE_CREATION_STEPS_KEYS } from "src/constants/keys/local-storage.keys";
import { IAPIBasePaginatedReqQuery } from "src/interfaces/api-request.interfaces";
import { ISupplierList } from "src/interfaces/suppliers";
import { useDeleteInvoiceByIdMutation } from "src/redux/api/saleAndInvoiceApi";
import { useGetSuppliersMutation } from "src/redux/api/suppliersAndProductsApi";
import { setSelectedSupplierId } from "src/redux/features/invoiceSlice";
import { setSelectedInvoiceId } from "src/redux/features/memberSlice";
import { buildValidObject } from "src/utils/common";
import RootLayout from "src/layout/Layout";
import { ChevronLeft } from "@mui/icons-material";
import { Box } from "@mui/material";
import ProductSelectionStepBreadcrumb from "src/components/member-product-selection-steps/ProductSelectStepBreadcrumb";
import { MuiTextField } from "src/ui-library";
import SupplierItem from "src/components/supplier/SupplierItem";

const SelectSupplierStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // LOCAL STORE...
  const [searchKeyword, setSearchKeyword] = useState("");
  const [supplierList, setSupplierList] = useState<Array<ISupplierList>>([]);
  const [pagination, setPagination] = useState<ISupplierPagination>({
    page: 1,
    pageSize: 10,
    hasMore: false,
  });

  // REDUX THINGS...
  const { selectedInvoiceId } = useSelector((state: any) => state.memberState);
  const { selectedSupplierId } = useSelector(
    (state: any) => state.invoiceState,
  );

  const [
    getSuppliers,
    {
      isLoading: isSupplierLoading,
      data: suppliersData,
      isSuccess: isSupplierDataSuccess,
      isError: isSupplierDataError,
    },
  ] = useGetSuppliersMutation();

  const [deleteInvoiceById, { isSuccess: isDeleteInvoiceSuccess }] =
    useDeleteInvoiceByIdMutation();

  useEffect(() => {
    setSupplierList([]);
    const timer = setTimeout(() => {
      getSuppliers(makeReqPayload({ page: 1 }));
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchKeyword]);

  // SAVING SUPPLIERS state...
  useEffect(() => {
    if (suppliersData) {
      setPagination({
        page: suppliersData.page,
        pageSize: suppliersData.pageSize,
        total: suppliersData.total,
        hasMore:
          suppliersData.page * suppliersData.pageSize <
          Number(suppliersData.total),
      });

      setSupplierList((prevState) => {
        if (prevState && suppliersData.data.length) {
          const oldState = [...prevState];

          // check if data already exist or not, if exist replace with new data...
          suppliersData.data.forEach((val) => {
            const ind = oldState.findIndex(
              (v) => v.supplier_name === val.supplier_name,
            );
            if (ind >= 0) oldState.splice(ind, 1, val);
            else oldState.push(val);
          });

          return oldState;
        } else {
          return [...suppliersData.data];
        }
      });
    }
  }, [isSupplierDataSuccess, isSupplierDataError]);

  useEffect(() => {
    if (isDeleteInvoiceSuccess) {
      dispatch(setSelectedInvoiceId(""));
      navigate(
        memberRoutes.productSelectionStep.selectProduct(selectedSupplierId),
      );
    }
  }, [isDeleteInvoiceSuccess]);

  const makeReqPayload = (custQuery?: IAPIBasePaginatedReqQuery) => {
    let obj = {
      page: pagination.page,
      page_size: pagination.pageSize,
      query: searchKeyword ? searchKeyword : undefined,
    } as IAPIBasePaginatedReqQuery;

    if (custQuery) {
      obj = { ...obj, ...custQuery };
    }
    return buildValidObject(obj);
  };

  // handle auto load paginated data while scrolling...
  const observer = useRef<IntersectionObserver | null>(null);
  const lastProductElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isSupplierLoading || !node) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && pagination.hasMore) {
          setPagination((prevState) => {
            return { ...prevState, page: prevState.page + 1 };
          });
          getSuppliers(makeReqPayload({ page: pagination.page + 1 }));
        }
      });
      observer.current.observe(node);
    },
    [isSupplierLoading, pagination.hasMore],
  );

  const handleSupplierClick = (supplierName: string) => {
    dispatch(setSelectedSupplierId(supplierName));
    localStorage.removeItem(INVOICE_CREATION_STEPS_KEYS.PAYMENT_INFO);

    if (selectedInvoiceId) {
      deleteInvoiceById({ invoiceId: selectedInvoiceId });
    } else {
      navigate(memberRoutes.productSelectionStep.selectProduct(supplierName));
    }
  };

  return (
    <RootLayout>
      <Box display="flex" alignItems="center">
        <StyledHeaderBackButton
          variant="outlined"
          startIcon={<ChevronLeft />}
          onClick={() => navigate(-1)}
        >
          返回
        </StyledHeaderBackButton>

        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          flexGrow={1}
        >
          <ProductSelectionStepBreadcrumb active={1} />
        </Box>
      </Box>

      <Box className="page__content supplier__list__page">
        <MuiTextField
          fullWidth
          placeholder="搜尋供應商編號或名稱"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />

        <DefaultLoaderContainer isLoading={isSupplierLoading}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              // gridTemplateColumns: "repeat(auto-fill, minmax(190px, 1fr))",
              rowGap: "10px",
              columnGap: 2.5,
            }}
          >
            {supplierList.map((supplier, index) => {
              if (supplierList.length === index + 1) {
                return (
                  <Box
                    key={`supplier-item-${index}`}
                    ref={lastProductElementRef}
                    onClick={() => handleSupplierClick(supplier.supplier_name)}
                    sx={{
                      cursor: "pointer",
                      width: "200px",
                      height: "123.48px",
                    }}
                  >
                    <SupplierItem supplier={supplier} />
                  </Box>
                );
              } else {
                return (
                  <Box
                    key={`supplier-item-${index}`}
                    onClick={() => handleSupplierClick(supplier.supplier_name)}
                    sx={{
                      cursor: "pointer",
                      width: "200px",
                      height: "123.48px",
                    }}
                  >
                    <SupplierItem supplier={supplier} />
                  </Box>
                );
              }
            })}

            {supplierList.length === 0 ? <Box>No Data Availabel</Box> : null}

            {/* {suppliersData?.data?.map((supplier, index: number) => (
              <Box
                key={`supplier-item-${index}`}
                sx={{ cursor: "pointer" }}
                onClick={() => handleSupplierClick(supplier.supplier_name)}
              >
                <SupplierItem supplier={supplier} />
              </Box>
            ))} */}
          </Box>
        </DefaultLoaderContainer>

        {/* <Box display="flex" justifyContent={"center"} alignItems={"center"}>
          {pagination.hasMore ? (
            <CustLoadingButton
              loadingIndicator={<CircularProgress />}
              loading={isSupplierLoading}
              variant="contained"
              onClick={handleLoadMore}
              sx={{ mt: 2 }}
              fullWidth={false}
            >
              Load More
            </CustLoadingButton>
          ) : null}
        </Box> */}
      </Box>
    </RootLayout>
  );
};

export default SelectSupplierStep;

// TYPE DECLERRATION...
interface ISupplierPagination {
  page: number;
  pageSize: number;
  total?: number;
  hasMore: boolean;
}
