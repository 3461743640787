import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import ProductDetailModal from "src/components/product/ProductDetailModal";
import ProductDetailModal from "src/components/product/ProductDetailModal1";
import { IInvoiceProductItemDetail } from "src/interfaces/invoice.interfaces";
import {
  useAddUpdateInvoiceItemMutation,
  useDeleteInvoiceByIdMutation,
  useDeleteInvoiceItemMutation,
  useGetInvoiceByIdMutation,
} from "src/redux/api/saleAndInvoiceApi";
import {
  IMemberState,
  setInvoiceDetails,
  setNeedRefeatchInvoice,
  setSelectedInvoiceId,
  setSelectedVariant,
} from "src/redux/features/memberSlice";
import { getValidImageUrl } from "src/utils/common";

const CouponInvoiceProductRow = (props: IInvoiceProductItemDetail) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // LOCAL STATES...
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalConfirmOpen, setIsDeleteModalConfirmOpen] =
    useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [remarks, setRemarks] = useState(props.custom_item_remarks ?? "");

  // REDUX THINGS..
  const { selectedInvoiceId, invoiceDetail: invoiceDetailInStore } =
    useSelector((state: any) => state.memberState as IMemberState);

  // REDUCER MUTATION...
  const [
    getInvoiceById,
    {
      data: invoiceDetail,
      isSuccess: isInvoiceDataSuccess,
      error: invoiceDetailError,
      isError: isInvoiceDetailError,
    },
  ] = useGetInvoiceByIdMutation();

  const [deleteInvoiceItem, { isSuccess: isDeleteSuccess }] =
    useDeleteInvoiceItemMutation();
  const [addUpdateInvoiceItem, { isSuccess: isUpdateSuccess }] =
    useAddUpdateInvoiceItemMutation();
  const [deleteInvoiceById, { isSuccess: isDeleteInvoiceSuccess }] =
    useDeleteInvoiceByIdMutation();

  // REFETCH INVOICE BY ID IF ITS DELETED, EDITED...
  useEffect(() => {
    if (isDeleteInvoiceSuccess) {
      navigate("/", { replace: true });
    }
    if (selectedInvoiceId) {
      getInvoiceById({ invoice_name: selectedInvoiceId });
    }
  }, [isUpdateSuccess, isDeleteInvoiceSuccess, isDeleteSuccess]);

  // SET INVOICE DETAIL IN STORE...
  useEffect(() => {
    if (isInvoiceDataSuccess && invoiceDetail) {
      dispatch(setInvoiceDetails(invoiceDetail.data));
      dispatch(setNeedRefeatchInvoice(true));
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isInvoiceDataSuccess]);

  // HANDLE ERROR
  useEffect(() => {
    const err = invoiceDetailError as any;
    if (isInvoiceDetailError && err && err.status === 404) {
      dispatch(setInvoiceDetails(null));
      dispatch(setSelectedInvoiceId(""));
    }
  }, [isInvoiceDetailError]);

  // HELPER FUNCTIONS...
  const deleteInvoice = () => {
    setIsLoading(true);
    deleteInvoiceById({ invoiceId: selectedInvoiceId });
  };

  const handleDeleteInvoiceItem = (itemId: string) => {
    if (invoiceDetailInStore && invoiceDetailInStore.items.length > 1) {
      setIsLoading(true);
      deleteInvoiceItem({ invoice_name: selectedInvoiceId, item_code: itemId });
    } else {
      setIsDeleteModalConfirmOpen(true);
    }
  };

  const handleUpdateItemQuantity = (
    item: IInvoiceProductItemDetail,
    isIncrease: boolean,
  ) => {
    setIsLoading(true);
    const remainingQty = isIncrease ? item.qty + 1 : item.qty - 1;
    if (
      invoiceDetailInStore &&
      invoiceDetailInStore.items.length === 1 &&
      remainingQty < 1
    ) {
      setIsDeleteModalConfirmOpen(true);
    }

    if (invoiceDetailInStore && remainingQty >= 1) {
      const payload = {
        invoice_name: selectedInvoiceId,
        item_code: item.item_code,
        item_qty: remainingQty,
        // remove_item_name: item.name,
      };
      addUpdateInvoiceItem(payload);
    } else {
      handleDeleteInvoiceItem(item.item_code);
    }
  };

  const handleEditSubmit = (isSuccess?: boolean) => {
    if (isSuccess) {
      setIsLoading(true);
      getInvoiceById({ invoice_name: selectedInvoiceId });
      dispatch(setSelectedVariant(null));
    }

    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleRemarksChange = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    const { value } = e.target;
    if (value && value !== props.custom_item_remarks) {
      addUpdateInvoiceItem({
        invoice_name: selectedInvoiceId,
        item_code: props.item_code,
        custom_item_remarks: value,
      });
    }
  };

  const handleEditBTNClick = () => {
    dispatch(
      setSelectedVariant({
        item_name: props.name,
        name: props.item_code,
        parent_item_code: props.parent_item_name,
      }),
    );
    setIsEditModalOpen(true);
  };

  const handleImageError = (e: any) => {
    e.target.src = "/images/NoImage.svg"; // Replace with the path to your default image
  };

  return (
    <Box>
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <Grid
          width={"100%"}
          container
          columnSpacing={0}
          aria-label={`product-item-section-${props.item_code}`}
          sx={{
            "& .MuiGrid-item": {
              "&:not(:last-child)": {
                borderRight: "1px solid #BDBDBD",
              },
            },
          }}
        >
          <Grid item xs={6}>
            <Box
              display="flex"
              gap="20px"
              alignItems="center"
              aria-label="invoice-product-item"
              pl={1}
              pr={4}
            >
              <Box width={158.625}>
                <img
                  alt={props.item_name ?? "product image"}
                  // src={generateRemoteMediaUrl(props.custom_template_thumbnail)}
                  src={getValidImageUrl(props.custom_template_thumbnail)}
                  style={{ objectFit: "contain" }}
                  width={"100%"}
                  onError={handleImageError}
                />
              </Box>

              <Stack spacing="10px" flexGrow={1}>
                <Box>
                  <Typography fontSize={18}>{props.item_code}</Typography>
                  <Typography fontWeight={700} fontSize={20}>
                    {props.item_name}
                  </Typography>
                </Box>

                <Typography fontSize={15}>
                  {props.custom_width ? (
                    <span>{props.custom_width}cm(W)</span>
                  ) : null}
                  {props.custom_depth ? (
                    <span> x {props.custom_depth}cm(D)</span>
                  ) : null}
                  {props.custom_height ? (
                    <span> x {props.custom_height}cm(H)</span>
                  ) : null}
                  {props.custom_size_remarks ? (
                    <span>[{props.custom_size_remarks}]</span>
                  ) : null}
                </Typography>

                <Typography fontSize={18}>
                  {props.custom_item_remarks}
                </Typography>

                {/* PRODUCT DETAIL SECTION... */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    spacing="6px"
                    direction="row"
                    alignItems="center"
                    height={40}
                  >
                    <Typography fontWeight={500} pr={0.5}>
                      數量:
                    </Typography>

                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        px: "10px",
                        py: "20px",
                        width: 40,
                        minWidth: 0,
                        height: "100%",
                      }}
                      onClick={() => handleUpdateItemQuantity(props, false)}
                    >
                      <RemoveIcon />
                    </Button>

                    <Box
                      sx={{
                        height: "100%",
                        width: "40px",
                        p: "8.2px",
                        borderRadius: "5.26px",
                        border: "1.053px solid #E0E0E0",
                      }}
                    >
                      <Typography fontWeight={700} textAlign="center">
                        {props.qty}
                      </Typography>
                    </Box>

                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        px: "10px",
                        py: "20px",
                        width: 40,
                        minWidth: 0,
                        height: "100%",
                      }}
                      onClick={() => handleUpdateItemQuantity(props, true)}
                    >
                      <AddIcon />
                    </Button>
                  </Stack>

                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#333",
                      color: "white",
                      "&:hover": { bgcolor: "#333" },
                    }}
                    onClick={() => handleDeleteInvoiceItem(props.item_code)}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>

                {/* PRODUCT EDIT SECTION... */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems={"center"}
                  gap={"10px"}
                >
                  {/* VARIANT DETAILS... */}
                  <Box
                    aria-label="variant-detail"
                    borderRadius="5px"
                    border="1px solid #E0E0E0"
                    p={1}
                    flexGrow={1}
                  >
                    {props.custom_display_item &&
                    props.custom_display_item === 1 ? (
                      <Typography
                        fontWeight={700}
                        fontSize={16}
                        color={"#3742F9"}
                      >
                        陳列品
                      </Typography>
                    ) : null}

                    {props.attributes.length ? (
                      props.attributes.map((attr, ind) => {
                        return (
                          <Typography
                            fontWeight={500}
                            id={attr.attribute}
                            key={ind}
                          >
                            <Box component="span">{attr.attribute}: </Box>
                            <Box component="span" fontWeight={400}>
                              {attr.attribute_value}
                            </Box>
                          </Typography>
                        );
                      })
                    ) : (
                      <Typography
                        fontWeight={500}
                        my={1.5}
                        display={
                          props.custom_display_item &&
                          props.custom_display_item === 1
                            ? "none"
                            : "block"
                        }
                      >
                        產品沒有選項
                      </Typography>
                    )}
                  </Box>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleEditBTNClick}
                    startIcon={<EditIcon />}
                    sx={{ p: 1, height: 82 }}
                  >
                    編輯
                  </Button>
                </Box>

                {/* REMARKS SECTION... */}
                <Stack spacing={2.5} direction="row" alignItems="center">
                  <Typography fontWeight={700}>備註</Typography>
                  <Box flexGrow={1}>
                    <TextField
                      variant="outlined"
                      placeholder="特別訂造：xxxx"
                      fullWidth
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      onBlur={handleRemarksChange}
                      sx={{ fontWeight: 500 }}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box display="flex" justifyContent="center" alignItems={"center"}>
              <Box textAlign="center">
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  sx={{ textDecorationLine: "line-through" }}
                >
                  $ {props.custom_original_price}
                </Typography>

                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color="#DC2427"
                  fontStyle="italic"
                >
                  {props.custom_markup_discount}% off
                </Typography>

                <Typography fontWeight={700} fontSize={24}>
                  $ {props.rate}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              fontSize={20}
              fontWeight={700}
            >
              {props.applied_coupons.length ? (
                <Stack spacing={1}>
                  {props.applied_coupons.map((d) => {
                    return (
                      <Box key={d.coupon_code} textAlign={"center"}>
                        <Typography
                          textAlign="end"
                          color="#E26F71"
                          fontSize={20}
                          fontWeight={700}
                        >
                          -$ {d.amount}
                        </Typography>
                        <Box
                          bgcolor="#E26F71"
                          p="15px"
                          borderRadius="5px"
                          color="#FFF"
                          fontSize={16}
                          fontWeight={700}
                        >
                          {d.coupon_name}
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              ) : (
                <>-</>
              )}
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Typography fontSize={24} fontWeight={700}>
                {props.custom_discounted_amount}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}

      <ProductDetailModal
        isModalOpen={isEditModalOpen}
        handleModalClose={handleEditSubmit}
        selectedProductCode={props.parent_item_name ?? props.item_code}
        isEditing={true}
      />

      {/* <ProductDetailModal
        isEditing={true}
        isModalOpen={isEditModalOpen}
        selectedProductCode={props.parent_item_name ?? props.item_code}
        handleModalClose={handleEditSubmit}
      /> */}

      {/* INVOICE DELETE ALERT MODAL...  */}
      <Dialog
        open={isDeleteModalConfirmOpen}
        aria-labelledby="invoice-delete-alert"
      >
        <DialogTitle id="invoice-delete-alert-title">
          刪除全部銷售項目
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="invoice-delete-alert-description">
            刪除全部銷售項目將導致整個銷售單被刪除，且無法恢復。確認刪除？
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => deleteInvoice()}
            variant="outlined"
            sx={{ textTransform: "unset" }}
          >
            好的，刪除
          </Button>

          <Button
            variant="outlined"
            sx={{ textTransform: "unset" }}
            onClick={() => {
              setIsDeleteModalConfirmOpen(false);
              setIsLoading(false);
            }}
            autoFocus
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CouponInvoiceProductRow;

const LoadingSkeleton = () => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={6}>
        <Stack direction="row" spacing={5} width="100%">
          <Skeleton
            sx={{ height: 158, width: 158 }}
            animation="wave"
            variant="rectangular"
          />

          <Stack spacing="10px" flexGrow={1}>
            <Skeleton
              sx={{ height: 40 }}
              animation="wave"
              variant="rectangular"
            />
            <Skeleton
              sx={{ height: 40 }}
              animation="wave"
              variant="rectangular"
            />
            <Skeleton
              sx={{ height: 40 }}
              animation="wave"
              variant="rectangular"
            />
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={2}>
        <Stack spacing={1}>
          <Skeleton
            sx={{ height: 16 }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            sx={{ height: 16 }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            sx={{ height: 16 }}
            animation="wave"
            variant="rectangular"
          />
        </Stack>
      </Grid>

      <Grid item xs={2}>
        <Stack spacing={1} justifyContent="center" height="100%">
          <Skeleton
            sx={{ height: 20, width: "100%" }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            sx={{ height: 20, width: "100%" }}
            animation="wave"
            variant="rectangular"
          />
        </Stack>
      </Grid>

      <Grid item xs={2}>
        <Stack spacing={1} justifyContent="center" height="100%">
          <Skeleton
            sx={{ height: 20 }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            sx={{ height: 20 }}
            animation="wave"
            variant="rectangular"
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
